import React from 'react'
import { NavLink } from 'react-router-dom'
import Text from '../textStyleDefault/texto'

interface BotonContactoMovilProps {
  texto: string
}

const BotonContactoMovil = (props: BotonContactoMovilProps): any => {
  return (
    <NavLink to="/contacto">
      <Text
        as="button"
        className="text-[#043873] bg-[#FFE492] px-[40px] py-2.5 border-none rounded-[8px]"
      >
        {props.texto}
      </Text>
    </NavLink>
  )
}

export default BotonContactoMovil
