/* eslint-disable react/react-in-jsx-scope */
import { useState } from 'react'
import { Routes, Route } from 'react-router-dom'
import Header from './components/shared/header/header'
import Footer from './components/shared/footer/footer'
import CookieWarning from './components/shared/cookieWarning/cookieWarning'
import Inicio from './components/views/inicio/inicio'
import Desarrollo from './components/views/desarrollo/desarrollo'
import Contacto from './components/views/contacto/contacto'
import Exitos from './components/views/casosExito/casosExito'
import PoliticaCookies from './components/shared/cookieWarning/cookiePolicy'
import LaEmpresa from './components/views/empresa/la-empresa'
import ScrollToTop from './ScrollToTop'

function App (): any {
  const [idioma, setIdioma] = useState('es')
  return (
    <div className="text-center overflow-x-hidden max-w-[1439px] m-auto">
      <Header cambiarIdioma={ setIdioma } />
      <ScrollToTop />
      <Routes>
        <Route index element={ <Inicio /> }></Route>
        <Route path="productos-y-servicios" element={ <Desarrollo idioma={ idioma } /> }></Route>
        <Route path="la-empresa" element={ <LaEmpresa /> }></Route>
        <Route path="contacto" element={ <Contacto /> }></Route>
        <Route path="casos-de-exito" element={ <Exitos /> }></Route>
        <Route path="politica-de-cookies" element={ <PoliticaCookies /> }></Route>
      </Routes>
      <CookieWarning />
      <Footer />
    </div>
  )
}

export default App
