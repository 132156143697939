import { useTranslation } from 'react-i18next'
import React from 'react'
import Text from '../textStyleDefault/texto' // Aseg�rate de importar el componente correctamente

interface ContactoProps {
  color: string
  iconoMail: string
  iconoUbi: string
}

const Contacto = (props: ContactoProps): any => {
  const { t } = useTranslation()

  return (
    <div className='flex flex-col gap-3 text-start '>
      <Text as="p" className={`flex items-center text-[${props.color}]`}>
        <img decoding="async" src={props.iconoMail} alt="email" width="25" height="25" />&nbsp;&nbsp;
        <Text as="a" href="mailto:info@criterian.es" target="_blank" rel="noreferrer"
          className={`text-${props.color}`} variant="body1">
          info@criterian.es
        </Text>
      </Text>
      <Text as='p' className={`flex items-center text-${props.color}`}>
        <img decoding="async" src={props.iconoUbi} alt="maps" width="25" height="25" />&nbsp;&nbsp;
        {t('direccion')} Madrid 28045
      </Text>
    </div>
  )
}

export default Contacto
