import React from 'react'
import BotonContacto from './boton-contacto'
import BotonContactoMovil from './boton-contacto-movil'

interface BotonContactoResponsiveProps {
  texto: string
}

const BotonContactoResponsive: any = ({ texto }: BotonContactoResponsiveProps) => {
  return (
    <div>
      {/* Botón para pantallas grandes */}
      <div className="hidden md:block">
        <BotonContacto texto={texto} />
      </div>

      {/* Botón para pantallas pequeñas */}
      <div className="block md:hidden">
        <BotonContactoMovil texto={texto}/>
      </div>
    </div>
  )
}

export default BotonContactoResponsive
