/* eslint-disable react/react-in-jsx-scope */
import { useTranslation } from 'react-i18next'
import linkImg from '../../../assets/images/casosExito/link.png'
import Text from '../../shared/textStyleDefault/texto'

const Caso = ({ data }: any): any => {
  const { t } = useTranslation()
  const alt = `logo ${data.nombre}`
  const logos = (data.logos).map((logo: any) => <img key={ logo } src={ logo } alt={ alt } className='w-[70%] h-auto'/>)

  return (
    <div className='flex mb-10 justify-center text-center shadow-[0_2px_8px_0_rgba(71,74,89,0.08),0_2px_2px_0_rgba(71,74,89,0.02)] rounded-lg pb-2 px-5'>
        <div className='h-auto pt-[5%]'>
          <div className='flex flex-col justify-center items-center h-[120px] w-[120px] bg-gray-50  mx-auto rounded-full '>
            { logos }
          </div>
          <div>
            <Text variant='subtitle2' className='text-center justify-center text-sky-900 leading-none tracking-tight mt-4'>{ data.nombre }</Text>
          </div>
          <br/>
          <div>
            <Text as='h6' className='self-stretch text-center justify-start text-stone-900 leading-normal tracking-tight'>{ t(`${data.texto}`) }</Text>
          </div>
          <br/>
            <a href={ data.urls[0] } target="_blank" rel="noreferrer">
              <img src={linkImg} alt="inicio" className='w-[32px] h-[32px] m-auto' />
            </a>
        </div>
    </div>
  )
}
export default Caso
