import React from 'react'
import constantes from '../../constantes/constantes'
import UnderlinedTitle from '../../shared/underlinedText/underlined-title'
import Text from '../../shared/textStyleDefault/texto'

const razones = constantes.razones

const RazonesCriterian = (): any => {
  return (
    <div className="inline-flex flex-col items-start gap-[65px] w-full bg-[#043873] text-white px-4 py-8">
      <UnderlinedTitle blanco={true} texto='¿Por qué Criterian?' palabrasSubrayadas='Criterian' width='993.70px' marginTop='3%'/>
      <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-4 gap-10 w-full max-w-[1250px] mx-auto mb-9">
        {razones.map((razon) => (
          <div key={razon.icono} className="flex flex-col items-center gap-6 text-center">
            <div>
              <img src={razon.icono} alt="icono" className="p-3 max-w-[80px]" />
            </div>
            <Text as='h5'>{razon.titulo}</Text>
            <Text variant='subtitle1'>{razon.texto}</Text>
          </div>
        ))}
      </div>
    </div>
  )
}

export default RazonesCriterian
