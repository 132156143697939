/* eslint-disable react/react-in-jsx-scope */
import { useTranslation } from 'react-i18next'
import { NavLink, useLocation } from 'react-router-dom'
import { useState } from 'react'
import Text from '../textStyleDefault/texto'

const CookieWarning = (): JSX.Element => {
  const { t } = useTranslation()
  const location = useLocation()
  const [isVisible, setIsVisible] = useState(true)

  const close = (): any => {
    setIsVisible(false)
  }

  return (
    <div
      className={`bottom-0 fixed block w-[70%] left-[50%] translate-x-[-50%] bg-[rgb(210,210,210)] p-[10px,20px,0px,20px] z-[2] ${
        location.pathname === '/politica-de-cookies' || !isVisible ? 'hidden' : 'block'
      }`}
    >
      <Text variant='subtitle1'>{t('cookies')}</Text>
      <button type='button' id='botonCookie' onClick={close} className='btn btn-secondary'>
        {t('cerrar')}
      </button>
      <Text variant='subtitle1'>
        {t('leermas')}{' '}
        <NavLink to='/politica-de-cookies'>{t('leermas-enlace')}</NavLink>
      </Text>
    </div>
  )
}

export default CookieWarning
