import React from 'react'
import constantes from '../../constantes/constantes'
import Text from '../../shared/textStyleDefault/texto'
const listaBeneficios = constantes.beneficios
const Beneficios = (): any => {
  return (
    <div className=" h-full w-[80%] mx-auto my-[5%]">
        <div className='w-full flex flex-row'>
        {listaBeneficios.map((beneficio) => (
          <div key={beneficio.icono} className="flex flex-col items-start justify-around text-start max-w-[20%] h-[40%] m-auto">
            <div>
              <img src={beneficio.icono} alt="icono" className="w-[64px] h-[64px]" />
            </div>
            <br />
            <Text as='h5' className='mb-[20px]'>{beneficio.titulo}</Text>
            <Text as='p' variant='subtitle1'>{beneficio.texto}</Text>
          </div>
        ))}
        </div>
    </div>
  )
}
export default Beneficios
