import React from 'react'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
interface NavbarLinksProps {
  color: string
  icon: string
  variant?: 'header' | 'footer'
}
const NavbarLinks = (props: NavbarLinksProps): any => {
  const { t } = useTranslation()
  return (
    <ul className={clsx('flex', { 'flex-row space-x-[32px] w-48 mx-auto whitespace-nowrap': props.variant === 'header', ' items-start flex-col space-y-[16px] right-[10%] ': props.variant === 'footer' })}>
     <li className={clsx('group', props.variant === 'header' && 'flex justify-center', props.variant === 'footer' && 'relative flex flex-col ')}>
        <NavLink to='productos-y-servicios/' className={`p-2 flex items-center text-${props.color} !text-[20px] cursor-pointer`}>
          {t('servicios')}
          <img src={props.icon} className={clsx('h-[24px] w-[24px] group-hover:rotate-180')} alt='arrow-down'/>
        </NavLink>
        <ul className={clsx('rounded-[8px] w-max text-left border-white border-[1px]   cursor-pointer',
          props.variant === 'header' && 'absolute mt-[45px] ml-[130px] hidden group-hover:block',
          props.variant === 'footer' && ' mt-2 hidden group-hover:block',
          props.color === 'white' ? 'bg-[#043873]' : 'bg-white'
        )}>
          <li className=' p-2 '><NavLink to='productos-y-servicios/' className={`text-${props.color} text-[16px] `}>{t('desarrollo de software')}</NavLink></li>
          <li className='p-2 '><a href='https://due-diligence.criterian.es/' className={`text-${props.color} text-[16px]`} target='_blank' rel='noreferrer'>{t('due')}</a></li>
        </ul>
      </li>
      <li className='block'><NavLink to='casos-de-exito/' className={`flex p-2 text-${props.color} !text-[20px]`}>{t('proyectos')}</NavLink></li>
      <li className='block'><NavLink to='la-empresa/' className={`flex p-2 text-${props.color} !text-[20px]`}>{t('nosotros')}</NavLink></li>
    </ul>
  )
}

export default NavbarLinks
