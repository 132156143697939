import React from 'react'
import ServiciosInicio from './servicios-inicio'
import ContactoBanner from '../../shared/contactBanner/contacto-banner'
import RazonesCriterian from './razones-criterian'
import GifInicio from './gif-inicio'
const Inicio = (): any => {
  return (
    <div>
      <GifInicio/>
      <RazonesCriterian />
      <ServiciosInicio />
      <ContactoBanner/>
    </div>
  )
}
export default Inicio
