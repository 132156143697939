/* eslint-disable react/react-in-jsx-scope */
import Banner from '../../shared/frontBanner/banner'
import ListaCasos from './listaCasos'
import ContactoBanner from '../../shared/contactBanner/contacto-banner'
import banner_casosExito from '../../../assets/images/casosExito/banner_casosExito.png'

const CasosExito = (): any => {
  return (
    <div id='main'>
      <Banner
      image={banner_casosExito}
      title="Nuestros Proyectos"
      />
      <ListaCasos />
      <ContactoBanner />
    </div>
  )
}
export default CasosExito
