import facebookIcon from '../../../assets/images/footer/facebook.png'
import twitterIcon from '../../../assets/images/footer/twitter.png'
import linkedinIcon from '../../../assets/images/footer/linkedin.png'
import Text from '../textStyleDefault/texto'
import React from 'react'
const SocialMedia = (): any => {
  return (
    <div className='w-[230px] h-[24px] flex items-start flex-row justify-between'>
      <Text variant='subtitle1' className='text-gray-500'>Social Media </Text>
      <a href="https://twitter.com/Criterian_Softw/" target="_blank" rel="noreferrer"><img decoding="async" src={twitterIcon} alt="twitter" width="25" height="25" /></a>
      <a href="https://www.linkedin.com/in/criterian-software-305241135" target="_blank" rel="noreferrer"><img decoding="async" src={linkedinIcon} alt="linkedin" width="25" height="25" /></a>
      <a href="https://www.facebook.com/criteriansoftware/" target="_blank" rel="noreferrer" ><img decoding="async" src={facebookIcon} alt="facebook" width="25" height="25" /></a>
    </div>
  )
}
export default SocialMedia
