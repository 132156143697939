import React from 'react'
import constantes from '../../constantes/constantes'
import BotonServicio from './boton-servicio'
import clsx from 'clsx'
import UnderlinedTitle from '../../shared/underlinedText/underlined-title'
import Text from '../../shared/textStyleDefault/texto'
const servicios = constantes.servicios
const ServiciosInicio = (): any => {
  return (
    <div className=' mt-[2%] mb-[7%] text-left  md:mt-[2%]'>
      <UnderlinedTitle texto='  Nuestros Servicios a Tu Servicio' palabrasSubrayadas='a Tu Servicio' blanco= {false} width='700.01px' marginTop='3%'/>
      <div className="w-[100%] md:w-[85%]  md:mx-auto mb-[5%] text-start flex flex-col relative md:mt-4">
        <div className="md:h-[60%] md:w-[90%] flex flex-col mt-3 ml-2 md:ml-0 md:mt-0 md:flex-row md:self-end md:text-end ">
          <div className="w-full md:w-[50%] text-left md:ml-24">
            <Text as='h5'>
              Ofrecemos servicios comprometidos para potenciar tus proyectos
              empresariales con soluciones efectivas
            </Text>
          </div>
          <div className="w-full md:w-1/2 text-left ml-0 md:ml-4 mt-4 md:!mt-0">
          <Text as='h6'>
              Desde soluciones tecnológicas innovadoras hasta estrategias
              personalizadas, creamos un camino hacia nuevas oportunidades de
              crecimiento.
            </Text>
          </div>
        </div>
      </div>
      {servicios.map((servicio, index) => {
        const reversed = index % 2 !== 0
        return (
          <div className={clsx(
            'flex flex-col md:flex-row justify-between md:items-center  w-[95%] md:w-[85%] mx-auto mb-[5%] md:mb-[2%] h-auto md:h-[60vh] gap-[10px]',
            reversed && 'md:flex-row-reverse'
          )} key={servicio.imagen}>
            <div className="w-full mt-2 md:mt-0 md:w-[50%] h-auto md:h-[100%]">
              <img src={servicio.imagen} alt="inicio" className="rounded-[10px] w-full h-auto md:h-full"/>
            </div>
            <div className="flex flex-col max-w-full md:w-[40%] py-4 px-2 md:px-6 overflow-auto">
              <Text as='h4' className='mb-4'>{servicio.titulo}</Text>
              <Text variant='subtitle1' className='mb-6 flex-grow'>
                {servicio.texto}
              </Text>
              <BotonServicio texto={servicio.textoBoton}/>
            </div>
          </div>
        )
      })}

    </div>
  )
}
export default ServiciosInicio
