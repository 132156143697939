import React from 'react'
import ListaDescripciones from './lista-descripciones'
import UnderlinedTitle from '../../shared/underlinedText/underlined-title'
interface Valor {
  titulo: string
  texto: string
}
type listas = Valor[]
interface DescripcionesProps {
  items: listas
  titulo: string
  beneficios: boolean
  subrayado: string
  blanco: boolean
}
const Descripciones = (props: DescripcionesProps): any => {
  return (
    <div className="max-w-[1200px]  flex justify-center">
        <div className='w-full flex justify-between'>
          <UnderlinedTitle blanco={false} texto='Nuestros valores' width='488px' palabrasSubrayadas='valores' marginTop='0px' divWidth='50%' />
          <ListaDescripciones items={props.items} />
        </div>
      </div>
  )
}

export default Descripciones
