/* eslint-disable react/react-in-jsx-scope */
import React from 'react'
import clsx from 'clsx'
import Text from '../textStyleDefault/texto'

interface BannerProps {
  image: string
  title: string
  subtitle?: string
  description?: string
  height?: string
}

const Banner: React.FC<BannerProps> = ({ image, title, subtitle, description, height = '363px' }) => {
  const hasSubtitle = Boolean(subtitle)
  const hasDescription = Boolean(description)

  return (
    <div
      className="flex justify-center items-center w-full bg-cover mt-[75px] md:mt-[100px]"
      style={{ backgroundImage: `url(${image})`, height }}
    >
      <div
        className={clsx(
          'flex flex-col justify-center items-center w-[993px] font-light p-4 bg-[rgba(206,214,224,0.4)]',
          {
            'text-center': !hasSubtitle
          })
        }
      >
        <Text as='h2' className='max-w-[90%] inline-block'>
          {title}
          {hasSubtitle && <Text as='span'> {subtitle}</Text>}
        </Text>
        {hasDescription && (
          <Text as='h6' className={clsx(
            'mt-4',
            { 'text-center': !hasDescription })}>
            {description}
          </Text>
        )}
      </div>
    </div>
  )
}

export default Banner
