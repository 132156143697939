import React from 'react'
import Text from '../../shared/textStyleDefault/texto'
const Invitacion = (): any => {
  return (
    <div className='w-[85%] h-[344px] flex items-center justify-center overflow-hidden my-[10%] bg-[#A7CEFC]'>
        <div className='w-[80%] flex flex-col items-center'>
          <Text as='h4'>
          ¿Listo para unirte a nosotros y hacer crecer tu experiencia?
          </Text >
          <br />
          <Text as='h5' className='max-w-[50%] line'>¡Nos encantaría conocer tu historia! Si estás interesado/a en realizar prácticas o formar parte nuestro equipo, ¡haznos saber en tu solicitud y estaremos encantados de explorar juntos nuevas oportunidades! Envía tu portafolio y CV a: </Text>
          <br />
          <a href="mailto:info@criterian.es" target="_blank" rel="noreferrer">
           <Text as='h4' className='text-[#043873] underline'>info@criterian.es</Text>
          </a>
        </div>
    </div>
  )
}
export default Invitacion
