import React from 'react'
import clsx from 'clsx'

interface TextProps {
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span' | 'a' | 'button'
  variant?: 'subtitle1' | 'subtitle2' | 'body1' | 'body2' | 'caption' | 'overline'
  children: React.ReactNode
  className?: string
  href?: string
  target?: string
  rel?: string
}

const Text = <T extends React.ElementType = 'p'>({
  as,
  children,
  className,
  variant,
  ...props
}: TextProps & React.ComponentPropsWithoutRef<T>): React.ReactElement => {
  const Tag = (as ?? 'p') as unknown as React.ElementType

  return (
    <Tag
      className={clsx(
        {
          'text-[102px] font-light': Tag === 'h1',
          'text-[36px] md:text-[64px] font-light': Tag === 'h2',
          'text-[24px] md:text-[52px] font-normal': Tag === 'h3',
          'text-[24px] md:text-[36px] font-normal': Tag === 'h4',
          'text-[16px] md:text-[24px] font-normal': Tag === 'h5',
          'text-[24px] md:text-[36px] font-light': Tag === 'span',
          'text-[14px] md:text-[20px] font-normal': Tag === 'h6',
          'text-[12px] md:text-[20px] font-normal': Tag === 'p',
          'text-[12px] md:text-[20px] font-normal underline': Tag === 'a',
          'text-[12px] md:text-[16px] font-normal': variant === 'subtitle1',
          'text-[14px] font-medium': variant !== undefined && ['subtitle2', 'button'].includes(variant),
          'text-[16px] font-medium': variant === 'body1',
          'text-[14px] font-normal': variant === 'body2',
          'text-[8px] font-normal': variant === 'overline'
        },
        className
      )}
      {...props}
    >
      {children}
    </Tag>
  )
}

export default Text
