/* eslint-disable react/react-in-jsx-scope */
import logoCriterian from '../../../assets/images/icons/cropped-criterian-icon-180x180.png'
import { useLocation } from 'react-router-dom'
import SocialMedia from './social_media'
import Contacto from './contacto'
import FooterOptions from './footer_options'
import mapsIcon from '../../../assets/images/footer/location_on.png'
import mailIcon from '../../../assets/images/footer/email.png'
import Text from '../textStyleDefault/texto'

const Footer = (): any => {
  const location = useLocation()
  return (
    <div className="bg-white mt-[5%] w-full" style={{ display: location.pathname === '/politica-de-cookies' ? 'none' : 'block' }}>
      <div className="min-h-[543px]  w-[85%] mx-auto border-t-2 border-[#043873] flex flex-col md:flex-row md:justify-between">
        <div className=" md:w-[338px] flex justify-center pt-[10%] pl-[5%]">
          <a href="/" title="Criterian"><img src={logoCriterian} alt="inicio" className=" w-[60px] h-[60px] lg:w-[70px] lg:h-[70px]" /></a>
        </div>
        <div className="hidden lg:block">
          <FooterOptions />
        </div>
        <div className="text-left flex flex-col lg:items-end lg:space-y-[66px] mt-[5%]">
          <div className='font-medium '>
            <Contacto color="black" iconoMail={mailIcon} iconoUbi={mapsIcon} />
          </div>
          <div className="block  lg:hidden ">
          <FooterOptions />
          </div>
          <div className='mt-[55px]'>
            <SocialMedia />
          </div>
          <div className='text-center mt-[35px]'>
            <Text variant='body2' className='text-gray-500'>© 2023 • Criterian.</Text>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
