import React from 'react'
import NavbarLinks from '../header/navbar-links'
import arrowDown from './../../../assets/images/header/keyboard_arrow_down _black.png'
const FooterOptions = (): any => {
  return (
      <div className='w-48 mt-[55px] lg:mt-[30%]'>
            <div>
              <NavbarLinks color='black' icon={arrowDown} variant="footer" />
            </div>
        </div>
  )
}
export default FooterOptions
