import React from 'react'
import Caso from './caso'
import constantes from '../../constantes/constantes'

const casosexito = constantes.casosexito

const CasosLista = (): any => {
  return (
    <div className='grid w-[1200px] grid-cols-1 sm:grid-cols-2 gap-[20px] py-[8%]'>
        {casosexito.map((data, index) => (
            <Caso key={index} data={data} />
        ))}
    </div>
  )
}

export default CasosLista
