/* eslint-disable react/react-in-jsx-scope */
import CasosLista from './casosLista'

const listaCasos = (): JSX.Element => {
  return (
    <div className='flex items-center justify-center'>
          <CasosLista />
    </div>
  )
}
export default listaCasos
